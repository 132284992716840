input[type=checkbox]{
  display: none;
}

input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: 4px;
  width: 1rem;
  height: 1rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.2rem;
  border-color: gray;
  margin-right: 0.5em;
}