@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');


button.connect_button {
  border-radius: 40px;
  font-size: 1.3rem;
  padding: 0.6rem 1.5rem;
  font-family: "Cairo",sans-serif;
  font-weight: 700;
}

button.connet_button:hover:not(:disabled) {
  filter: brightness(120%);
}

button.connect_button:disabled {
  filter: brightness(80%);
}