@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');

button.top_bar_button {
  border-radius: 4rem;
  background-color: rgba(0,0,0,0.2);
  padding: 0.7rem 1.15rem;
  color: #cecece;
  font-weight: 700;
  font-family: "Cairo",sans-serif;
  border-style: none;
  font-size: 1rem;
}

button.top_bar_button:hover{
  background-color: rgba(0,0,0,0.1);
}

button {
  margin-top: 1em;
}