@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');

div.top_left_stack{
  display: flex; 
  flex-direction: column; 
  align-items: center;
  text-shadow: 2px 2px 8px #222;
  padding: 0.5em 1em;
}

.top_left_stack > p {
  position: relative;
  top: -3.2rem;
  font-weight: 100;
  font-size: larger;
  color: #dcdcdc;
  font-family: "Cairo",sans-serif;
}