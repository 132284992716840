@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');

div.no_token_div {
  display: flex;
  flex-direction: column;
  background-color: rgba(0,0,0,0.486);
  padding: 1em;
  border-radius: 3px;
  font-size: larger;
  width: 40vw;
  color: #cecece;
  font-family: "Cairo",sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
}
.no_token_div p{
  text-align: center;
}
.no_token_div ul {
  list-style-type: none;
}
.no_token_div a{
  color: white;
}
.no_token_div a:visited{
  color: white;
}
.no_token_div img{
  width: 50px;
}
div.nft_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}