@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');


.terms_conditions > a {
  color: #456633;
  font-family: "Cairo",sans-serif;
  font-weight: 600;
}
.terms_conditions > a:visited {
  color: #5f8b47;
}