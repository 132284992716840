@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@100;400&display=swap');



#loading_information h2 {
  color: #dcdcdc;
  font-size: 2.5em;
  text-shadow: 2px 2px 8px #222;
  margin: 0px;
}

#loading_information h1 {
  color: #dcdcdc;
  font-size: 3.5em;
  text-shadow: 2px 2px 8px #222;
  margin: 0px;
}

div#loading_information{
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-family: "Cairo",sans-serif;
}